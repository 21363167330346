<template lang="pug">
.landing-page__block(:class="fromCard && 'border-box'" @click="onSupportClick")
  .support-block
    .support-block__left
      .secondary-title Проконсультируем и покажем систему в работе!
      .landing-page__divider._margin
        span._separator
    .service-order-form
      order-form(
        v-model="form"
        ref="orderFormRef"
        @confirm="createOrder(22, '', YmGoals.ConsultGoal)"
      )
      .agreement-wrapper
        agreement(mode="agreement")
        ui-button.order-button(:loader="isSaving" :icon-right="UiIconNames.Icon_Support" @click.stop="validate") Заказать консультацию
      .success-message(v-if="showSuccessMessage") Ваше обращение принято, ожидайте звонка.
      .error-message(v-if="showErrorMessage") Произошла ошибка при отправке обращения, пожалуйста, попробуйте позднее.
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useOrderForm } from "@/use/tenderService/useOrderForm";
import { sendMetrica, YmGoals } from "~/utils/logger/metrica";
import UiIconNames from "@/components/ui/icon/UiIconNames";

import OrderForm from "@/components/orderForm/OrderForm.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import Agreement from "@/components/authorization/dialogContent/Agreement.vue";

export default defineComponent({
  name: "SupportBlock",
  components: {
    UiButton,
    Agreement,
    OrderForm,
  },
  props: {
    fromCard: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const {
      form,
      isSaving,
      orderFormRef,
      showErrorMessage,
      showSuccessMessage,
      validate,
      createOrder,
    } = useOrderForm(undefined, false);

    function onSupportClick() {
      sendMetrica(YmGoals.ConsultClick)
    }

    return {
      form,
      isSaving,
      orderFormRef,
      showErrorMessage,
      showSuccessMessage,
      YmGoals,
      validate,
      createOrder,
      onSupportClick,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/landing/landing";
@import "@/assets/styles/forms/orderForm";
@import '@/assets/styles/mixin/fonts';

.error-message {
  @include error-message;
}

.success-message {
  font-size: 14px !important;
  font-weight: 600;
  @include success-message;
}

.border-box {
  padding: 0;
}

.support-block {
  @include white-box;

  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 32px;

  justify-content: space-between;
  padding: 32px 60px;
}

.support-block__left {
  display: flex;
  flex-flow: column;
}

.landing-page__divider {
  justify-content: start;
}

.service-order-form {
  display: flex;
  flex-flow: column;
  gap: 16px;
}

@media (max-width: 1000px) {
  .support-block {
    display: flex;
    flex-flow: column;
    gap: 0;
    padding: 32px 44px;
  }
}

@media (max-width: 640px) {
  .support-block {
    padding: 32px 32px;
  }
}
</style>
